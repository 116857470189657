$primary: #0a084e;
$primary-light: rgba($primary, .4);
$secondary: #ec5c46;
$light: rgba(128, 73, 169, 0.15);
$green: #00c9b8;
$blue-light: #0099CB;
$page-bg: #f7f9fd;
$purple: #747CF3;
$white: white;
$black: black;
$dark: #1d252d;
$text-light: #d9d9d9;
$text-dark: #212a36;
$middle: #8e9aa0;
$success: #00c48c;
$success-light: rgba($color: $success, $alpha: 0.11);
$error: #ff375e;
$error-light: rgba($color: $error, $alpha: 0.11);
$inprogress: #d6a100;
$inprogress-light: rgba($color: $inprogress, $alpha: 0.11);
$box-shadow: 0 3px 12px rgba(6, 11, 47, 0.06);
$background-dark: #1950E1