/* How to use */
/*
.some-div{
  @include small-screen{
    width: 100%;
  }
}
*/
/* Screen Sizes */
/* Source Fonts */
@import url("https://fonts.googleapis.com/css2?family=Tajawal&display=swap");
@font-face {
  font-family: "Source Light";
  font-display: swap;
  src: url("/assets/fonts/TitilliumWeb-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Source Regular";
  font-display: swap;
  src: url("/assets/fonts/TitilliumWeb-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Source Bold";
  font-display: swap;
  src: url("/assets/fonts/TitilliumWeb-Bold.ttf") format("truetype");
}
/* Project Fonts */
body {
  font-family: "Source Regular", sans-serif;
}
body.rtl {
  font-family: "Tajawal", sans-serif;
}
body.rtl * {
  font-family: "Tajawal", sans-serif;
}
body.rtl * h1,
body.rtl * h2,
body.rtl * h3,
body.rtl * h4,
body.rtl * h5,
body.rtl * h6 {
  font-family: "Tajawal", sans-serif !important;
}
body * {
  font-family: "Source Regular", sans-serif;
}
body h1:not(.regular),
body h2:not(.regular),
body h3:not(.regular),
body h4:not(.regular),
body h5:not(.regular),
body h6:not(.regular) {
  font-family: "Source Bold", sans-serif;
}
body h1.regular,
body h2.regular,
body h3.regular,
body h4.regular,
body h5.regular,
body h6.regular {
  font-family: "Source Regular", sans-serif;
}

.font-english {
  font-family: "Source Regular", sans-serif !important;
}

.font-arabic {
  font-family: "Tajawal", sans-serif !important;
}

.font-10 {
  font-size: 0.675rem !important;
}

.font-12 {
  font-size: 0.75rem !important;
}

.font-14 {
  font-size: clamp(.8rem, 1vw, .9rem) !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16, body {
  font-size: clamp(.8rem, 1vw, 1.75rem) !important;
}

.font-18 {
  font-size: clamp(1.15rem, 1vw, 2rem) !important;
}

.font-20 {
  font-size: clamp(1.35rem, 1vw, 2.15rem) !important;
}

.font-22 {
  font-size: clamp(1.5rem, 1vw, 2.3rem) !important;
}

.font-24 {
  font-size: clamp(1.65rem, 1vw, 2.45rem) !important;
}

.font-26 {
  font-size: clamp(1.8rem, 1vw, 2.6rem) !important;
}

button:focus {
  box-shadow: none !important;
  outline: none !important;
}
button .mat-button-focus-overlay {
  background: white;
}

.btn-disabled {
  background-color: transparent !important;
}

.main-btn {
  border-radius: 0 !important;
  min-height: 34px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  transition: none;
}
.main-btn * {
  transition: none;
}
.main-btn:disabled {
  color: #fff !important;
  background-color: #e3e5ee !important;
  border: 1.5px solid #e3e5ee !important;
}
.main-btn.white {
  background-color: white;
  color: #0a084e;
}
.main-btn.main {
  background-color: #0a084e;
  color: white;
}
.main-btn.light {
  background-color: rgba(10, 8, 78, 0.4);
  color: white;
}
.main-btn.secondary {
  background-color: #ec5c46;
  color: white;
}
.main-btn.secondary:disabled {
  background-color: #e3e5ee;
  color: #ccc;
}
.main-btn.flat {
  border: none;
  color: #0a084e;
}
.main-btn.strock {
  border: 1.5px solid #1d252d;
  color: black;
  background-color: transparent;
}
.main-btn.strock.active {
  background-color: #0a084e;
  color: white;
}
.main-btn.strock-bg {
  background-color: white;
  border-color: #0a084e !important;
  color: #0a084e;
}
.main-btn.strock-bg.active {
  background-color: #0a084e;
  color: white;
}
.main-btn.strock-bg.danger {
  background-color: white !important;
  border-color: #ff375e !important;
  color: #ff375e !important;
}
.main-btn.error {
  border-color: #ff375e !important;
}
.main-btn .mat-button-wrapper {
  width: 100%;
  line-height: 30px;
}

.table-wrapper.cursor-pointer .mat-table .mat-row {
  cursor: pointer;
}
.table-wrapper .mat-table {
  box-shadow: none;
}
@media only screen and (min-width: 640px) {
  .table-wrapper .mat-table {
    width: 100% !important;
  }
}
.table-wrapper .mat-table .mat-header-row {
  background-color: rgba(255, 255, 255, 0.37);
}
.table-wrapper .mat-table .mat-header-row .mat-header-cell {
  flex: 0 0 90px;
}
@media only screen and (min-width: 640px) {
  .table-wrapper .mat-table .mat-header-row .mat-header-cell {
    flex: 1;
  }
}
.table-wrapper .mat-table .mat-header-row .mat-header-cell.mat-column-index, .table-wrapper .mat-table .mat-header-row .mat-header-cell.mat-column-checkbox {
  flex: 0 0 60px;
}
.table-wrapper .mat-table .mat-header-row .mat-header-cell.default {
  min-width: max-content;
  max-width: 160px;
}
.table-wrapper .mat-table .mat-header-row .mat-header-cell.small {
  min-width: max-content;
  max-width: 80px;
}
.table-wrapper .mat-table .mat-header-row .mat-header-cell.medium {
  min-width: max-content;
  max-width: 250px;
}
.table-wrapper .mat-table .mat-row {
  background: #fff;
  border-bottom-color: transparent;
}
.table-wrapper .mat-table .mat-row.selected {
  background-color: rgba(221, 224, 226, 0.59);
}
.table-wrapper .mat-table .mat-row .mat-cell {
  flex: 0 0 90px;
}
@media only screen and (min-width: 640px) {
  .table-wrapper .mat-table .mat-row .mat-cell {
    flex: 1;
  }
}
.table-wrapper .mat-table .mat-row .mat-cell.mat-column-index, .table-wrapper .mat-table .mat-row .mat-cell.mat-column-checkbox {
  flex: 0 0 60px;
}
.table-wrapper .mat-table .mat-row .mat-cell.default {
  min-width: max-content;
  max-width: 160px;
}
.table-wrapper .mat-table .mat-row .mat-cell.small {
  min-width: max-content;
  max-width: 80px;
}
.table-wrapper .mat-table .mat-row .mat-cell.medium {
  min-width: max-content;
  max-width: 250px;
}
@media only screen and (min-width: 640px) {
  .table-wrapper .mat-table .mat-row .mat-cell.max-width {
    max-width: 110px;
  }
}
@media only screen and (min-width: 1024px) {
  .table-wrapper .mat-table .mat-row .mat-cell.max-width {
    max-width: 175px;
  }
}
@media only screen and (min-width: 1400px) {
  .table-wrapper .mat-table .mat-row .mat-cell.max-width {
    max-width: 190px;
  }
}
@media only screen and (min-width: 1636px) {
  .table-wrapper .mat-table .mat-row .mat-cell.max-width {
    max-width: 270px;
  }
}
@media only screen and (min-width: 2200px) {
  .table-wrapper .mat-table .mat-row .mat-cell.max-width {
    max-width: 430px;
  }
}

.table-wrapper .mat-table .mat-header-row .mat-header-cell {
  font-weight: bold;
}

.table-wrapper .mat-table .mat-row:hover {
  background-color: #f9fbfd;
  cursor: pointer;
}

a {
  text-decoration: none !important;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.5 !important;
}

input::placeholder {
  color: #1d252d !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body {
  text-align: start;
}
body.rtl {
  direction: rtl;
}
body.rtl .cdk-global-overlay-wrapper {
  direction: rtl;
}
body .cdk-global-overlay-wrapper {
  direction: ltr;
}

.mat-menu-panel {
  margin-top: 0.5rem;
  min-width: 130px !important;
  min-height: 40px !important;
}
.mat-menu-panel .mat-menu-content {
  padding: 0 !important;
}
.mat-menu-panel .mat-menu-content .mat-list .mat-list-item {
  height: 40px !important;
}
.mat-menu-panel .mat-menu-content .mat-list .mat-list-item .mat-list-item-content .mat-menu-item {
  height: 40px !important;
  align-items: center;
}

.mat-slider:not(.mat-slider-disabled).cdk-focused .mat-slider-thumb-label {
  border-radius: 0;
  transform: none;
  background-color: white;
  box-shadow: 0 2px 10px 0 rgba(142, 154, 160, 0.22);
}
.mat-slider:not(.mat-slider-disabled).cdk-focused .mat-slider-thumb-label .mat-slider-thumb-label-text {
  transform: none;
  color: #0a084e;
}
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #0a084e;
}
.mat-slider .mat-slider-wrapper {
  height: 6px !important;
}
.mat-slider .mat-slider-wrapper .mat-slider-track-wrapper {
  border-radius: 2.5px;
  height: 6px !important;
}
.mat-slider .mat-slider-wrapper .mat-slider-track-wrapper * {
  height: 6px !important;
}
.mat-slider .mat-slider-wrapper .mat-slider-track-wrapper .mat-slider-track-background {
  background-color: #f3f3f1 !important;
}
.mat-slider .mat-slider-wrapper .mat-slider-track-wrapper .mat-slider-track-fill {
  background-color: #ec5c46 !important;
  transition: all 0.4s ease;
}
.mat-slider .mat-slider-wrapper .mat-slider-thumb {
  border-radius: 2px;
  box-shadow: 0 4px 10px 0 rgba(12, 0, 51, 0.22);
  background-color: #0a084e !important;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #0a084e !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #0a084e !important;
}

.cdk-overlay-pane {
  max-height: 90vh !important;
}
.cdk-overlay-pane .mat-dialog-container {
  min-width: 90%;
  height: 90vh;
}
@media only screen and (min-width: 640px) {
  .cdk-overlay-pane.small .mat-dialog-container {
    width: clamp(400px, 50vw, 400px);
    height: auto;
  }
}
@media only screen and (min-width: 640px) {
  .cdk-overlay-pane.medium .mat-dialog-container {
    width: clamp(600px, 50vw, 650px);
    height: auto;
  }
}
.cdk-overlay-pane.min-width .mat-dialog-container {
  min-width: 90%;
}
@media only screen and (min-width: 640px) {
  .cdk-overlay-pane.min-width .mat-dialog-container {
    min-width: 600px;
  }
}
.cdk-overlay-pane.default .mat-dialog-container {
  min-width: 90%;
  height: 90vh;
}
@media only screen and (min-width: 640px) {
  .cdk-overlay-pane.default .mat-dialog-container {
    min-width: clamp(600px, 50vw, 600px);
    height: 80vh;
  }
}

.mat-checkbox .mat-checkbox-layout {
  margin: 0;
}
.mat-checkbox .mat-ripple-element {
  background-color: #ff375e;
}
.mat-checkbox.mat-checkbox-checked.mat-bg-error .mat-checkbox-background, .mat-checkbox.mat-checkbox-indeterminate.mat-bg-error .mat-checkbox-background {
  background-color: #ff375e;
}

.cdk-overlay-container .mat-tooltip-panel .mat-tooltip {
  background-color: white;
  color: black;
  margin: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.32), 0 0 2px 0 rgba(0, 0, 0, 0.04);
}

.end-1 {
  right: 1rem;
}
.end-1.rtl {
  right: unset;
  left: 1rem;
}

.ng-select.ng-select-focused .ng-select-container.ng-appearance-outline::after {
  border-color: #1d252d !important;
  border-radius: 0;
}
.ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
  color: #1d252d;
}
.ng-select.ng-invalid.ng-dirty .ng-select-container.ng-appearance-outline::after {
  border-width: 2px;
  border-color: #ff375e !important;
}
.ng-select .ng-select-container.ng-appearance-outline::after {
  border-color: #1d252d;
  border-radius: 0;
}
.ng-select .ng-select-container .ng-value-container {
  min-height: 60px;
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  margin-top: 4px;
  background-color: #fff;
}
.ng-select .ng-select-container .ng-value-container .ng-value {
  display: flex;
  align-items: center;
}

.carousel-indicators > li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #1d252d;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

.carousel-indicators {
  right: 0%;
  bottom: -29%;
}

.rtl .carousel-indicators {
  left: 25%;
  right: auto;
}

a.carousel-control-prev span,
a.carousel-control-next span {
  display: none !important;
}

.custom-tooltip {
  color: white !important;
  background-color: black !important;
  padding: 5px 10px;
}

.emotions.rtl {
  flex-direction: row-reverse !important;
}
.emotions .happy:hover, .emotions .happy.active {
  fill: #00c48c;
}
.emotions .good:hover, .emotions .good.active {
  fill: #ffbf00;
}
.emotions .okay:hover, .emotions .okay.active {
  fill: #d6a100;
}
.emotions .bad:hover, .emotions .bad.active {
  fill: #ff375e;
}
.emotions .terrible:hover, .emotions .terrible.active {
  fill: #c52747;
}
.emotions .like:hover, .emotions .like.active {
  fill: #747CF3;
}
.emotions svg {
  transition: all 0.25s ease;
  cursor: pointer;
}

.text-dark {
  color: #1d252d !important;
}

.text-main {
  color: #0a084e !important;
}

.text-main-light {
  color: rgba(10, 8, 78, 0.4);
}

.text-secondary {
  color: #ec5c46 !important;
}

.text-middle {
  color: #8e9aa0 !important;
}

.text-light {
  color: rgba(128, 73, 169, 0.15) !important;
}

.text-white {
  color: white !important;
}

.text-black {
  color: black;
}

.text-success {
  color: #00c48c !important;
}

.text-error {
  color: #ff375e !important;
}

.text-pending {
  color: #d6a100 !important;
}

.text-start {
  text-align: start !important;
}

.text-truncate-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.word-break {
  word-break: break-all;
}

.bg-main {
  background-color: #0a084e !important;
}

.bg-main-light {
  background-color: rgba(10, 8, 78, 0.4);
}

.bg-main-light-2 {
  background-color: rgba(10, 8, 78, 0.05);
}

.bg-secondary {
  background-color: #ec5c46 !important;
}

.bg-middle {
  background-color: #8e9aa0 !important;
}

.bg-bluelight {
  background-color: #0099CB !important;
}

.bg-light {
  background-color: rgba(128, 73, 169, 0.15) !important;
}

.bg-succes {
  background-color: rgba(0, 196, 140, 0.11) !important;
}

.bg-error {
  background-color: rgba(255, 55, 94, 0.11) !important;
}

.bg-purple {
  background-color: #747CF3 !important;
}

.border-purple {
  border: 1px solid #747CF3;
}

.cursor-pointer {
  cursor: pointer;
}

.h-40 {
  height: 40px;
}

.w-80 {
  width: 100%;
}
@media only screen and (min-width: 640px) {
  .w-80 {
    width: 80%;
  }
}

.bottom-0 {
  bottom: 0;
}

.title-border-bottom {
  border-bottom: 2px solid #dde0e2;
}

.border-main {
  border-color: #0a084e !important;
}

.border-error {
  border-color: #ff375e !important;
}

.border-radius-0 {
  border-radius: 0;
}

.border-radius-2 {
  border-radius: 2px !important;
}

.border-radius-50 {
  border-radius: 50%;
}

.m-inline-start-1 {
  margin-inline-start: 0.25rem !important;
}

.m-inline-start-2 {
  margin-inline-start: 0.5rem !important;
}

.m-inline-start-3 {
  margin-inline-start: 1rem !important;
}

.m-inline-end-1 {
  margin-inline-end: 0.25rem !important;
}

.m-inline-end-2 {
  margin-inline-end: 0.5rem !important;
}

.m-inline-end-3 {
  margin-inline-end: 1rem !important;
}

.p-inline-start-1 {
  padding-inline-start: 0.25rem !important;
}

.p-inline-start-2 {
  padding-inline-start: 0.5rem !important;
}

.p-inline-start-3 {
  padding-inline-start: 1rem !important;
}

.p-inline-start-4 {
  padding-inline-start: 2rem !important;
}

.p-inline-start-5 {
  padding-inline-start: 4rem !important;
}

.p-inline-end-1 {
  padding-inline-end: 0.25rem !important;
}

.p-inline-end-2 {
  padding-inline-end: 0.5rem !important;
}

.p-inline-end-3 {
  padding-inline-end: 1rem !important;
}

.p-inline-end-4 {
  padding-inline-end: 2rem !important;
}

.p-inline-end-5 {
  padding-inline-end: 4rem !important;
}

@media only screen and (min-width: 1024px) {
  .xl-p-inline-end-5 {
    padding-inline-end: 4rem !important;
  }
}

.badge-circle {
  width: 35px;
  min-width: 35px !important;
  height: 35px;
  max-height: 35px !important;
  border-radius: 50% !important;
}

.badge-square {
  width: 30px;
  min-width: 30px !important;
  height: 25px;
  max-height: 25px !important;
  border-radius: 2px !important;
}

.w-max {
  width: max-content;
}

.page-wrapper {
  padding: 1.5rem clamp(1rem, 5vw, 2.5rem) !important;
}

.page-title {
  font-size: clamp(1.2rem, 1.9vw, 2.5rem) !important;
}

.rtl .rotate {
  display: block;
  transform: rotate(180deg);
}

/* Scrollbar Styles Start Here*/
/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d2d2d2;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0a084e;
}